import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { HttpClient, provideHttpClient, withFetch } from '@angular/common/http';
import { environment } from '../environments/environment';
import { ApiServiceConstants, ApiHouseholdService, ApiHttpService } from '@mercer/shared/data-access-api-household-ng';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideHttpClient(withFetch()),
    { provide: 'environment', useValue: environment },
    HttpClient,
    ApiServiceConstants,
    ApiHouseholdService,
    ApiHttpService,
    provideAnimationsAsync(),
  ],
};

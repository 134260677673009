// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  env: 'dev',
  redact: true,
  apiHost: 'devapi.merceradvisors.com',
  dataDogToken: 'pub37a7aec08dd90b91f0f287698fe07a0c',
  dataDogAppId: '225e759e-a760-46eb-af68-8163b8915c8f',
  newRetirementUrl: 'https://mercer-staging.newretirement.com/auth/mercer/jwt/?jwt=',
};

// export const environment = {
//   production: false,
//   env: 'dev',
//   redact: true,
//   mapApiKey: 'AIzaSyB0I24GHRwYIwdD-qTtNqT5SVVOpjH1Ssg',
//   apiHost: 'devapi.merceradvisors.com',
//   fastlinkUrl: 'https://finapp.mercerstage.yodlee.com/authenticate/mercer/fastlink/',
//   newRetirementUrl: 'https://mercer-staging.newretirement.com/auth/mercer/jwt/?jwt=',
//   dataDogToken: 'pub37a7aec08dd90b91f0f287698fe07a0c',
//   dataDogAppId: '225e759e-a760-46eb-af68-8163b8915c8f'
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

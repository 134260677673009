import { Injectable } from '@angular/core';

@Injectable()
export class ApiServiceConstants {
  public readonly API_ENDPOINT: string = 'https://devapi.merceradvisors.com/api/v1';
  public readonly API_DEV_ENDPOINT: string = 'https://devapi.merceradvisors.com/api/v1';
  public readonly API_MOCK_ENDPOINT: string = 'https://devapi.merceradvisors.com/api/v1';

  /* public readonly API_ENDPOINT: string = 'https://localhost:44355/api/v1'; 
    public readonly API_DEV_ENDPOINT: string = 'https://localhost:44355/api/v1'; 
    public readonly API_MOCK_ENDPOINT: string = 'https://localhost:44355/api/v1'; */

  //area endpoints
  public readonly API_ENDPOINT_PROFILES: string = 'Profiles';
  public readonly API_ENDPOINT_PROFILES_GET: string = 'Profiles/Get';
  public readonly API_ENDPOINT_PROFILES_UPDATE: string = 'Profiles/Update';
  public readonly API_ENDPOINT_PROFILES_LOGIN: string = 'Login';
  public readonly API_ENDPOINT_PROFILES_TEST_PROFILES: string = 'TestProfiles';

  public readonly API_ENDPOINT_ACCOUNTS: string = 'Accounts';
  public readonly API_ENDPOINT_ACCOUNTS_ALL: string = 'Accounts/All';

  public readonly API_ENDPOINT_ACCOUNTS_HOLDINGS_ALL: string = 'Accounts/Holdings/All';
  public readonly API_ENDPOINT_ACCOUNTS_HOLDINGS_DETAILS: string = 'Accounts/Holdings';
  public readonly API_ENDPOINT_ACCOUNTS_HOLDINGS_INCOME: string = 'Accounts/Holdings/IncomeSchedule';

  //households
  public readonly API_ENDPOINT_CONTACT: string = 'Salesforce/Contacts/Get';
  public readonly API_ENDPOINT_CONTACT_REGISTRATION_ELIGIBILITY: string =
    'Salesforce/Contacts/Registration/Eligibility';
  public readonly API_ENDPOINT_CONTACT_USER_NAME: string = 'Salesforce/Contacts/UserName';
  public readonly API_ENDPOINT_HOUSEHOLD_ADVISOR: string = 'Salesforce/Households/Advisor';
  public readonly API_ENDPOINT_HOUSEHOLD_INSIGHTS: string = 'Salesforce/Households/Insights';

  // Identity
  public readonly API_ENDPOINT_IDENTITY_LOGIN: string = 'Identity/SignIn';
  public readonly API_ENDPOINT_IDENTITY_LOGOUT: string = 'Identity/SignOut';
  public readonly API_ENDPOINT_IDENTITY_REGISTER: string = 'Identity/SignUp';
  public readonly API_ENDPOINT_IDENTITY_CONFIRM_REGISTRATION: string = 'Identity/VerifySignUp';
  public readonly API_ENDPOINT_IDENTITY_CONFIRM_MFA_CHALLENGE: string = 'Identity/RespondToMFAChallenge';

  public readonly API_ENDPOINT_IDENTITY_RESET_PASSWORD: string = 'Identity/ResetPassword';
  public readonly API_ENDPOINT_IDENTITY_UPDATE_PASSWORD: string = 'Identity/UpdatePassword';
  public readonly API_ENDPOINT_IDENTITY_CHANGE_PASSWORD: string = 'Identity/ChangePassword';
  public readonly API_ENDPOINT_IDENTITY_ASSOCIATE_MFA: string = 'Identity/AssociateMFA';
  public readonly API_ENDPOINT_IDENTITY_CONFIRM_ASSOCIATE_CODE: string = 'Identity/VerifyAssociationCode';
  public readonly API_ENDPOINT_IDENTITY_RESEND_CONFIRMATION_CODE: string = 'Identity/ResendConfirmationCode';
  public readonly API_ENDPOINT_IDENTITY_QR_CODE: string = 'Identity/QRCode';
  public readonly API_ENDPOINT_IDENTITY_IDENTITY_SETS_COUNT: string = 'Identity/IdentitySetsCount';

  public readonly API_ENDPOINT_IDENTITY_VERIFY_SMS: string = 'Identity/SendSMSVerificationCode';
  public readonly API_ENDPOINT_IDENTITY_CONFIRM_SMS: string = 'Identity/VerifySMS';

  public readonly API_ENDPOINT_IDENTITY_ALLOWED_PAYMENT_METHOD: string = 'Accounts/GetAuthorizationIncluded';
  public readonly API_ENDPOINT_IDENTITY_GET_PAYMENT_METHOD: string = 'Accounts/GetAuthorizationDetails';
  public readonly API_ENDPOINT_IDENTITY_GET_HAS_PAYMENT_METHOD: string = 'Accounts/GetHasAuthorizationDetails';
  public readonly API_ENDPOINT_IDENTITY_CREATE_AUTHORIZATION_OBJECT: string =
    'Accounts/CreatePaymentAuthorizationObject';
  public readonly API_ENDPOINT_IDENTITY_REMOVE_PAYMENT_METHOD: string = 'Accounts/RemovePaymentMethod';

  public readonly API_ENDPOINT_IDENTITY_IS_STILL_ACTIVE: string = 'Identity/IsStillActive';
  public readonly API_ENDPOINT_IDENTITY_IS_IN_MAINTENANCE_MODE: string = 'Identity/IsInMaintenanceMode';
  public readonly API_ENDPOINT_IDENTITY_CONTEXT: string = 'Identity/Claims';

  public readonly API_ENDPOINT_IDENTITY_IDENTITY_HOUSEHOLD: string = 'Identity/IdentityHousehold';
  public readonly API_ENDPOINT_IDENTITY_IDENTITY_SAML: string = 'Identity/ProcessSamlRequest';
  public readonly API_ENDPOINT_IDENTITY_IDENTITY_NEW_RETRIREMENT_SSO = 'Identity/NewRetirementSSO';
  public readonly API_ENDPOINT_IDENTITY_IDENTITY_EMONEY_SSO = 'Identity/eMoneySSO';
  public readonly API_ENDPOINT_IDENTITY_IDENTITY_FREEWILL_SSO = 'Identity/FreeWillSSO';

  public readonly API_ENDPOINT_LOG_MESSAGE: string = 'LogClientMessage';

  public readonly API_ENDPOINT_TASK_NEW: string = 'Salesforce/Tasks';
  public readonly API_ENDPOINT_TASKS_PORTAL_VISIBLE_FOR_HOUSEHOLD: string = 'Salesforce/Tasks/Household';
  public readonly API_ENDPOINT_RETIREMENT_ACCOUNTS_ADDED: string = 'Salesforce/Tasks/RetirmentAccountsAdded';

  public readonly API_ENDPOINT_ACCOUNTS_PERFORMANCE_ALL: string = 'Accounts/Performance/All';
  public readonly API_ENDPOINT_ACCOUNTS_PERFORMANCE_DETAILS: string = 'Accounts/Performance';

  public readonly API_ENDPOINT_ACCOUNTS_ACTIVITY_ALL: string = 'Accounts/Activity';
  public readonly API_ENDPOINT_ACCOUNTS_ACTIVITY_SNAPSHOT: string = 'Accounts/ActivitySnapshot';
  public readonly API_ENDPOINT_ACCOUNTS_UNREALIZED_GAINS: string = 'Accounts/UnrealizedGainLoss';
  public readonly API_ENDPOINT_ACCOUNTS_REALIZED_GAINS: string = 'Accounts/RealizedGainLoss';
  public readonly API_ENDPOINT_ACCOUNTS_VALUE_OVER_TIME: string = 'Accounts/ValueOverTime';
  public readonly API_ENDPOINT_ACCOUNTS_DOCUMENT_VAULT_TOKEN: string = 'DocumentVault/Folder';
  public readonly API_ENDPOINT_ACCOUNTS_TRANSACTIONS: string = 'Accounts/Transactions';

  public readonly API_ENDPOINT_ANNOUNCEMENTS_ALL_ACTIVE: string = 'Announcements/AllActive';
  public readonly API_ENDPOINT_EXTERNAL_ACCOUNTS_TOKEN: string = 'ExternalAccount/Token';
  public readonly API_ENDPOINT_EXTERNAL_ACCOUNTS_ACCOUNTS: string = 'ExternalAccount/Accounts';
  public readonly API_ENDPOINT_ACCOUNTS_BALANCE_SHEET: string = 'Accounts/BalanceSheet';
  public readonly API_ENDPOINT_EXTERNAL_ACCOUNTS_PROVIDER_ACCOUNTS = 'ExternalAccount/ProviderAccounts';
  public readonly API_ENDPOINT_EXTERNAL_ACCOUNTS_DELETE: string = 'ExternalAccount';
  public readonly API_ENDPOINT_EXTERNAL_ACCOUNTS_LAUNCH_BRIDGE_401k_DETAILS =
    'ExternalAccount/Bridge401k/LaunchDetails';
  public readonly API_ENDPOINT_EXTERNAL_ACCOUNTS_INACCESSIBLE_BRIDGE_401k_ACCOUNTS =
    'ExternalAccount/Bridge401k/InaccessibleAccounts';
  public readonly API_ENDPOINT_EXTERNAL_ACCOUNTS_LAUNCH_INACCESSIBLE_BRIDGE_401k_PARAMETERS =
    'ExternalAccount/Bridge401k/LaunchInaccessibleParameters';
  public readonly API_ENDPOINT_EXTERNAL_ACCOUNTS_ACTION_TAKEN_401k_CLIENT =
    'ExternalAccount/Bridge401k/ActionTakenByClient';

  public readonly API_ENDPOINT_ACCOUNTS_QPRS: string = 'Accounts/QPRS';
  public readonly API_ENDPOINT_ACCOUNTS_QPR_STATEMENT: string = 'Accounts/QPR/Statement';
  public readonly API_ENDPOINT_ACCOUNTS_STATEMENTS: string = 'Accounts/Statements';
  public readonly API_ENDPOINT_ACCOUNTS_TAX_STATEMENTS: string = 'Accounts/TaxStatements';
  public readonly API_ENDPOINT_ACCOUNTS_PRIVATE_MARKET_STATEMENTS: string = 'Accounts/PrivateMarketsStatements';
  public readonly API_ENDPOINT_ACCOUNTS_STATEMENT_PDF: string = 'Accounts/Statement';

  public readonly API_ENDPOINT_PLANNING_TASKS: string = 'Planning/Tasks';
  public readonly API_ENDPOINT_PLANNING_OBSERVATIONS: string = 'Planning/Observations';
  public readonly API_ENDPOINT_PLANNING_MONTECARLO: string = 'Planning/MonteCarlo';
  public readonly API_ENDPOINT_PLANNING_SNAPSHOT: string = 'Planning/PlanSnapShot';
  public readonly API_ENDPOINT_PLANNING_NETWORTH: string = 'Planning/NetWorth';
  public readonly API_ENDPOINT_PLANNING_GOALSANDEXPENSES: string = 'Planning/GoalsAndExpenses';
  public readonly API_ENDPOINT_PLANNING_INCOMES: string = 'Planning/Incomes';
  public readonly API_ENDPOINT_PLANNING_PLATFORMS: string = 'Planning/Platforms';
  public readonly API_ENDPOINT_PLANNING_SET_NR_PROFILE: string = 'Planning/SetNRProfile';
  public readonly API_ENDPOINT_PLANNING_PLANDETAILS: string = 'Planning/PlanDetails';

  public readonly API_ENDPOINT_PLANNING_ASSETS: string = 'Planning/GetAssets';
  public readonly API_ENDPOINT_PLANNING_ANNUITIES: string = 'Planning/GetAnnuities';
  public readonly API_ENDPOINT_PLANNING_CASHS: string = 'Planning/GetCashs';
  public readonly API_ENDPOINT_PLANNING_CONTRIBUTIONS: string = 'Planning/GetContributions';
  public readonly API_ENDPOINT_PLANNING_DEFERREDCOMPENSATION: string = 'Planning/GetDeferredCompensation';
  public readonly API_ENDPOINT_PLANNING_FIVE29PLANS: string = 'Planning/GetFive29Plans';
  public readonly API_ENDPOINT_PLANNING_HSAS: string = 'Planning/GetHsas';
  public readonly API_ENDPOINT_PLANNING_PERSONALPROPERTIES: string = 'Planning/GetPersonalProperties';
  public readonly API_ENDPOINT_PLANNING_QUALIFIEDRETIREMENTS: string = 'Planning/GetQualifiedRetirements';
  public readonly API_ENDPOINT_PLANNING_REALESTATES: string = 'Planning/GetRealEstates';
  public readonly API_ENDPOINT_PLANNING_ROTHIRAS: string = 'Planning/GetRothIras';
  public readonly API_ENDPOINT_PLANNING_TAXABLEINVESTMENTS: string = 'Planning/GetTaxableInvestments';
  public readonly API_ENDPOINT_PLANNING_LIABILITIES: string = 'Planning/GetLiabilities';
  public readonly API_ENDPOINT_PLANNING_CREDITLINE: string = 'Planning/GetCreditLine';
  public readonly API_ENDPOINT_PLANNING_FIXEDTERMS: string = 'Planning/GetFixedTerms';
  public readonly API_ENDPOINT_PLANNING_MORTGAGES: string = 'Planning/GetMortgages';
  public readonly API_ENDPOINT_PLANNING_STUDENTLOANS: string = 'Planning/GetStudentLoans';
  public readonly API_ENDPOINT_PLANNING_INSURANCES: string = 'Planning/GetInsurances';
  public readonly API_ENDPOINT_PLANNING_BUSINESSDISABILITIES: string = 'Planning/GetBusinessDisabilities';
  public readonly API_ENDPOINT_PLANNING_DISABILITIES: string = 'Planning/GetDisabilities';
  public readonly API_ENDPOINT_PLANNING_LIFES: string = 'Planning/GetLifes';
  public readonly API_ENDPOINT_PLANNING_LONGTERMCARES: string = 'Planning/GetLongTermCares';

  public readonly API_ENDPOINT_HOME_PAGE_BANNER: string = 'Announcements/HomePageBanner';
}

import { Injectable } from '@angular/core';
import {
  ServiceResponse,
  Task,
  Platforms,
  Plan,
  Asset,
  Annuity,
  Cash,
  Contribution,
  DeferredCompensation,
  Five29Plan,
  Hsa,
  Liability,
  PersonalProperty,
  QualifiedRetirement,
  RealEstate,
  RothIra,
  TaxableInvestment,
  CreditLine,
  FixedTerm,
  Mortgage,
  StudentLoan,
  BusinessDisability,
  Disability,
  Insurance,
  Life,
  LongTermCare,
} from '@mercer/shared/util-domain-ng';

import { Observable, of } from 'rxjs';

import { ApiServiceConstants } from './api-constants.service';
import { ApiEndpointsService } from './api-endpoints.service';
import { ApiHttpService } from './api-http.service';
import { Inject } from '@angular/core';

@Injectable()
export class ApiHouseholdService extends ApiEndpointsService {
  constructor(
    @Inject('environment') environment: any,
    // Application Constants
    constants: ApiServiceConstants,
    private apiHttpService: ApiHttpService
  ) {
    super(constants, environment);
  }

  public getAllTasks(): Observable<ServiceResponse<Task[]>> {
    return this.apiHttpService.get<ServiceResponse<Task[]>>(
      this.createUrl(this.constants.API_ENDPOINT_TASKS_PORTAL_VISIBLE_FOR_HOUSEHOLD)
    );
  }

  public createTask(
    subject: string,
    priority: string,
    taskType: string,
    taskSubType: string,
    request: string,
    comments: string
  ): Observable<ServiceResponse<string>> {
    return this.apiHttpService.post(this.createUrl(this.constants.API_ENDPOINT_TASK_NEW), {
      Client_Portal_Initiated_Request__c: true,
      Client_Portal_Visible__c: true,
      Priority: priority,
      Status: 'New',
      Subject: subject,
      Type: taskType,
      TaskSubtype: taskSubType,
      Client_Portal_Comments__c: comments,
      Client_Portal_Request__c: request,
    });
  }

  public getPlanDetails(withIndividualCategories: boolean): Observable<ServiceResponse<Plan>> {
    const endpoint = `${this.constants.API_ENDPOINT_PLANNING_PLANDETAILS}/?withIndividualCategories=${withIndividualCategories}`;
    return this.apiHttpService.get<ServiceResponse<Plan>>(this.createUrlWithPathVariables(endpoint));
  }

  public getPlatforms(): Observable<ServiceResponse<Platforms>> {
    return this.apiHttpService.get<ServiceResponse<Platforms>>(
      this.createUrl(this.constants.API_ENDPOINT_PLANNING_PLATFORMS)
    );
  }

  public getAssets(): Observable<ServiceResponse<Asset[]>> {
    return this.apiHttpService.get<ServiceResponse<Asset[]>>(
      this.createUrl(this.constants.API_ENDPOINT_PLANNING_ASSETS)
    );
  }
  public getAnnuities(): Observable<ServiceResponse<Annuity[]>> {
    return this.apiHttpService.get<ServiceResponse<Annuity[]>>(
      this.createUrl(this.constants.API_ENDPOINT_PLANNING_ANNUITIES)
    );
  }
  public getCashs(): Observable<ServiceResponse<Cash[]>> {
    return this.apiHttpService.get<ServiceResponse<Cash[]>>(this.createUrl(this.constants.API_ENDPOINT_PLANNING_CASHS));
  }
  public getContributions(): Observable<ServiceResponse<Contribution[]>> {
    return this.apiHttpService.get<ServiceResponse<Contribution[]>>(
      this.createUrl(this.constants.API_ENDPOINT_PLANNING_CONTRIBUTIONS)
    );
  }
  public getDeferredCompensation(): Observable<ServiceResponse<DeferredCompensation>> {
    return this.apiHttpService.get<ServiceResponse<DeferredCompensation>>(
      this.createUrl(this.constants.API_ENDPOINT_PLANNING_DEFERREDCOMPENSATION)
    );
  }
  public getFive29Plans(): Observable<ServiceResponse<Five29Plan[]>> {
    return this.apiHttpService.get<ServiceResponse<Five29Plan[]>>(
      this.createUrl(this.constants.API_ENDPOINT_PLANNING_FIVE29PLANS)
    );
  }
  public getHsas(): Observable<ServiceResponse<Hsa[]>> {
    return this.apiHttpService.get<ServiceResponse<Hsa[]>>(this.createUrl(this.constants.API_ENDPOINT_PLANNING_HSAS));
  }
  public getPersonalProperties(): Observable<ServiceResponse<PersonalProperty[]>> {
    return this.apiHttpService.get<ServiceResponse<PersonalProperty[]>>(
      this.createUrl(this.constants.API_ENDPOINT_PLANNING_PERSONALPROPERTIES)
    );
  }
  public getQualifiedRetirements(): Observable<ServiceResponse<QualifiedRetirement[]>> {
    return this.apiHttpService.get<ServiceResponse<QualifiedRetirement[]>>(
      this.createUrl(this.constants.API_ENDPOINT_PLANNING_QUALIFIEDRETIREMENTS)
    );
  }
  public getRealEstates(): Observable<ServiceResponse<RealEstate[]>> {
    return this.apiHttpService.get<ServiceResponse<RealEstate[]>>(
      this.createUrl(this.constants.API_ENDPOINT_PLANNING_REALESTATES)
    );
  }
  public getRothIras(): Observable<ServiceResponse<RothIra[]>> {
    return this.apiHttpService.get<ServiceResponse<RothIra[]>>(
      this.createUrl(this.constants.API_ENDPOINT_PLANNING_ROTHIRAS)
    );
  }
  public getTaxableInvestments(): Observable<ServiceResponse<TaxableInvestment[]>> {
    return this.apiHttpService.get<ServiceResponse<TaxableInvestment[]>>(
      this.createUrl(this.constants.API_ENDPOINT_PLANNING_TAXABLEINVESTMENTS)
    );
  }
  public getLiabilities(): Observable<ServiceResponse<Liability[]>> {
    return this.apiHttpService.get<ServiceResponse<Liability[]>>(
      this.createUrl(this.constants.API_ENDPOINT_PLANNING_LIABILITIES)
    );
  }
  public getCreditLine(): Observable<ServiceResponse<CreditLine>> {
    return this.apiHttpService.get<ServiceResponse<CreditLine>>(
      this.createUrl(this.constants.API_ENDPOINT_PLANNING_CREDITLINE)
    );
  }
  public getFixedTerms(): Observable<ServiceResponse<FixedTerm[]>> {
    return this.apiHttpService.get<ServiceResponse<FixedTerm[]>>(
      this.createUrl(this.constants.API_ENDPOINT_PLANNING_FIXEDTERMS)
    );
  }
  public getMortgages(): Observable<ServiceResponse<Mortgage[]>> {
    return this.apiHttpService.get<ServiceResponse<Mortgage[]>>(
      this.createUrl(this.constants.API_ENDPOINT_PLANNING_MORTGAGES)
    );
  }
  public getStudentLoans(): Observable<ServiceResponse<StudentLoan[]>> {
    return this.apiHttpService.get<ServiceResponse<StudentLoan[]>>(
      this.createUrl(this.constants.API_ENDPOINT_PLANNING_STUDENTLOANS)
    );
  }
  public getInsurances(): Observable<ServiceResponse<Insurance[]>> {
    return this.apiHttpService.get<ServiceResponse<Insurance[]>>(
      this.createUrl(this.constants.API_ENDPOINT_PLANNING_INSURANCES)
    );
  }
  public getBusinessDisabilities(): Observable<ServiceResponse<BusinessDisability[]>> {
    return this.apiHttpService.get<ServiceResponse<BusinessDisability[]>>(
      this.createUrl(this.constants.API_ENDPOINT_PLANNING_BUSINESSDISABILITIES)
    );
  }
  public getDisabilities(): Observable<ServiceResponse<Disability[]>> {
    return this.apiHttpService.get<ServiceResponse<Disability[]>>(
      this.createUrl(this.constants.API_ENDPOINT_PLANNING_DISABILITIES)
    );
  }
  public getLifes(): Observable<ServiceResponse<Life[]>> {
    return this.apiHttpService.get<ServiceResponse<Life[]>>(this.createUrl(this.constants.API_ENDPOINT_PLANNING_LIFES));
  }
  public getLongTermCares(): Observable<ServiceResponse<LongTermCare[]>> {
    return this.apiHttpService.get<ServiceResponse<LongTermCare[]>>(
      this.createUrl(this.constants.API_ENDPOINT_PLANNING_LONGTERMCARES)
    );
  }

  public setNRProfile(): Observable<ServiceResponse<boolean>> {
    return this.apiHttpService.post(this.createUrl(this.constants.API_ENDPOINT_PLANNING_SET_NR_PROFILE));
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionStorageService } from './storage/session-storage.service';

@Injectable()
export class ApiHttpService {
  constructor(private http: HttpClient, private sessionStorageService: SessionStorageService) {}

  public get(url: string, options?: any): Observable<ArrayBuffer>;

  public get<T>(url: string, options?: any): Observable<T>;

  public get<T>(url: string, options?: any): any {
    return this.http.get<T>(url, { ...options, withCredentials: true, headers: this.httpHeaders() });
  }

  public post<T>(url: string, options?: any): Observable<T>;

  public post(url: string, data: any, options?: any) {
    return this.http.post(url, data, { ...options, withCredentials: true, headers: this.httpHeaders() });
  }

  public postform(url: string, data: any, options?: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    });
    const token = this.sessionStorageService.get(this.sessionStorageService.ACCESS_TOKEN_KEY);
    const sessionId = this.sessionStorageService.get(this.sessionStorageService.SESSION_ID);
    if (token !== null) {
      headers = headers.append('Authorization', `Bearer ${token}`);
    }
    if (sessionId !== null) {
      headers = headers.append('SessionId', `${sessionId}`);
    }
    return this.http.post(url, data, { ...options, withCredentials: true, headers: headers });
  }

  public put(url: string, data: any, options?: any) {
    return this.http.put(url, data, { ...options, withCredentials: true, headers: this.httpHeaders() });
  }

  public delete(url: string, options?: any) {
    const result = this.http.delete(url, { ...options, withCredentials: true, headers: this.httpHeaders() });
    return result;
  }

  httpHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const token = this.sessionStorageService.get(this.sessionStorageService.ACCESS_TOKEN_KEY);
    if (token !== null) {
      headers = headers.append('Authorization', `Bearer ${token}`);
    }
    const sessionId = this.sessionStorageService.get(this.sessionStorageService.SESSION_ID);
    if (sessionId !== null) {
      headers = headers.append('SessionId', `${sessionId}`);
    }
    return headers;
  }
}

import { Routes } from '@angular/router';
import { InitiateComponentGuard } from './initiate-guard';

//import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

export const routes: Routes = [
  {
    path: '',
    canActivate: [InitiateComponentGuard],
    loadChildren: () => import('@mercer/net-worth-feature-main-ng').then(m => m.featureMainRoutes),
  },
  // {
  //   path: '',
  //   canActivate: [InitiateComponentGuard],
  //   redirectTo: 'net-worth-page',
  //   pathMatch: 'full',
  // },
  // {
  //   path: '**',
  //   component: PageNotFoundComponent, // should redirect to 404 page.
  // },
];

import { Injectable } from '@angular/core';
import { TrustedContext } from '@mercer/shared/util-domain-ng';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  public readonly ACCESS_TOKEN_KEY: string = 'access-token'; // bearer acccess token for authenticated calls to Mercer API
  public readonly USER_NAME_KEY: string = 'user-name'; // logged in user name (email)
  public readonly LOGIN_DATA_KEY: string = 'login-data';
  public readonly TRUSTED_CONTEXT_KEY: string = 'trusted-context';
  public readonly PHONE_VERIFIED: string = 'phone-verified';
  public readonly IDENTITY_SETS_COUNT: string = 'identity-sets-count';
  public readonly IMPERSONATION: string = 'impersonation';
  public readonly MFA_TYPE: string = 'mfa-type';
  public readonly SAML_REQUEST: string = 'saml-request';
  public readonly RELAY_STATE: string = 'relay-state';
  public readonly BRIDGE401K_QUERY_PARAMETERS_KEY: string = 'pontera-query-parameters';
  public readonly ROUTE_ROLE_TYPE: string = 'route-role-type';
  public readonly ACR_ROLES: string = 'acr-roles';
  public readonly SESSION_ID: string = 'session-id';
  private readonly THIRD_PARTY_TECHNOLOGY_ACCESS_ROLE: string = 'Third Party Technology Access';
  private _password = '';

  constructor() {
    // placeholder
  }

  signOut(): void {
    //window.sessionStorage.clear();
    this.remove(this.ACCESS_TOKEN_KEY);
    this.remove(this.USER_NAME_KEY);
    this.remove(this.LOGIN_DATA_KEY);
    this.remove(this.TRUSTED_CONTEXT_KEY);
    this.remove(this.PHONE_VERIFIED);
    this.remove(this.IDENTITY_SETS_COUNT);
    this.remove(this.IMPERSONATION);
    this.remove(this.MFA_TYPE);
    this.remove(this.BRIDGE401K_QUERY_PARAMETERS_KEY);
    this.remove(this.ROUTE_ROLE_TYPE);
    this.remove(this.ACR_ROLES);
    this.remove(this.SESSION_ID);
  }

  public save(key: string, value: string): void {
    const hashkey = this.stringToHash(key);
    window.sessionStorage.removeItem(hashkey);
    window.sessionStorage.setItem(hashkey, value);
  }

  public get(key: string): string {
    const hashkey = this.stringToHash(key);
    const val = window.sessionStorage.getItem(hashkey);

    if (val !== null) {
      return val;
    }
    return '';
  }

  public remove(key: string): void {
    const hashkey = this.stringToHash(key);
    window.sessionStorage.removeItem(hashkey);
  }

  public isUserLoggedIn(): boolean {
    return !!this.get(this.ACCESS_TOKEN_KEY);
  }

  public getContext(): TrustedContext {
    return JSON.parse(this.get(this.TRUSTED_CONTEXT_KEY)) as TrustedContext;
  }

  public setContext(context: TrustedContext): void {
    this.save(this.TRUSTED_CONTEXT_KEY, JSON.stringify(context));
  }

  public get Password(): string {
    return this._password;
  }

  public set Password(pass: string) {
    this._password = pass;
  }

  public get HasBridge401kQueryParameters(): boolean {
    const qp = this.get(this.BRIDGE401K_QUERY_PARAMETERS_KEY);
    return qp !== '';
  }

  public ClearBridge401kQueryParameters() {
    this.remove(this.BRIDGE401K_QUERY_PARAMETERS_KEY);
  }

  public get RouteRoleType(): string {
    return this.get(this.ROUTE_ROLE_TYPE);
  }

  public set RouteRoleType(type: string) {
    this.save(this.ROUTE_ROLE_TYPE, type);
  }

  public get AcrRoles(): string[] {
    let roles: string[] = [];
    const val = this.get(this.ACR_ROLES);

    try {
      if (val && val !== '') {
        roles = this.get(this.ACR_ROLES).split(',');
      }
    } catch {
      //TODO: are we adding a logo tracker?
    }

    return roles;
  }

  public set AcrRoles(roles: string[]) {
    this.save(this.ACR_ROLES, roles.length > 0 ? roles.join(',') : '');
  }

  public get HasThirdPartyTechnologyAccessOnly(): boolean {
    let hasAccess = false;
    try {
      hasAccess = this.AcrRoles.length === 1 && this.AcrRoles.includes(this.THIRD_PARTY_TECHNOLOGY_ACCESS_ROLE);
    } catch {
      //TODO: are we adding a logo tracker?
    }

    return hasAccess;
  }

  public get IsPhoneVerified(): boolean {
    const val = this.get(this.PHONE_VERIFIED);
    return val === 'true';
  }

  public set IsPhoneVerified(verified: boolean) {
    this.save(this.PHONE_VERIFIED, verified.toString());
  }

  public get IdentitySetsCount(): number {
    let val = this.get(this.IDENTITY_SETS_COUNT);
    if (!val && val === '') {
      val = '0';
    }
    return Number(val);
  }

  public set IdentitySetsCount(count: number) {
    this.save(this.IDENTITY_SETS_COUNT, count.toString());
  }

  public get IsImpersonating(): boolean {
    const val = this.get(this.IMPERSONATION);
    return val === 'true';
  }

  public set IsImpersonating(impersonating: boolean) {
    this.save(this.IMPERSONATION, impersonating.toString());
  }

  public get MfaType(): string {
    return this.get(this.MFA_TYPE);
  }

  public set MfaType(type: string) {
    this.save(this.MFA_TYPE, type);
  }

  public get HasMfaType() {
    const type = this.MfaType;
    return type && type !== '';
  }

  private stringToHash(key: string): string {
    let hash = 0;
    if (key.length == 0) {
      return hash.toString();
    }
    for (let i = 0; i < key.length; i++) {
      const c = key.charCodeAt(i);
      hash = (hash << 5) - hash + c;
      hash = hash & hash;
    }
    return hash.toString();
  }
}
